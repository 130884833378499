import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import REACT_APP_BASE_URL from "../../utilities/api";
import tokenHandler from "../../utilities/tokenHandler";
import SnackbarToast from "../common/SnackbarToast";
import ViewTicket from "./ViewTicket";

const columns = [
  { id: "sr", label: "Sr. No", align: "center" },
  { id: "ticket_no", label: "Ticket No", align: "center" },
  { id: "vendor_name", label: "Vendor", align: "center" },
  { id: "subject", label: "Subject", align: "center" },
  { id: "priority", label: "Priority", align: "center" },
  { id: "status", label: "Status", align: "center" },
  { id: "date", label: "Date", align: "center" },
];

export default function Ticket() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allData, setAllData] = React.useState([]);
  const [showData, setShowData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState({ msg: "", variant: "" });
  const [editData, setEditData] = React.useState({});

  const token = tokenHandler();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (snackOpen) {
      setTimeout(() => {
        setSnackOpen(false);
        setSnackMsg({ msg: "", variant: "" });
      }, [10000]);
    }
  }, [snackOpen]);

  const getTicket = async () => {
    setLoader(true);
    await axios
      .get(`${REACT_APP_BASE_URL}/ticket`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(async (response) => {
        let addsr = [];
        response.data.ticket.forEach((val, index) => {
          addsr.push({
            ...val,
            vendor_name: val.vendor.owner_name,
            date: new Date(val.createdAt)
              .toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .split("-")
              .reverse()
              .join("-"),
          });
          if (
            Object.entries(editData).length > 0 &&
            editData.ticket_no === val.ticket_no
          ) {
            setEditData({
              ...val,
              vendor_name: val.vendor.owner_name,
              date: new Date(val.createdAt)
                .toLocaleDateString("fr-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("-")
                .reverse()
                .join("-"),
            });
          }
        });

        let filter = [];
        await response.data.ticket.forEach((val, index) => {
          if (
            new Date(val.createdAt).toLocaleDateString() !==
              new Date().toLocaleDateString() &&
            val.status.toLowerCase() !== "completed"
          ) {
            filter.push({
              ...val,
              vendor_name: val.vendor.owner_name,
              date: new Date(val.createdAt)
                .toLocaleDateString("fr-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("-")
                .reverse()
                .join("-"),
            });
          }
        });

        await response.data.ticket.forEach((val, index) => {
          if (
            new Date(val.createdAt).toLocaleDateString() ===
            new Date().toLocaleDateString()
          ) {
            filter.push({
              ...val,
              vendor_name: val.vendor.owner_name,
              date: new Date(val.createdAt)
                .toLocaleDateString("fr-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("-")
                .reverse()
                .join("-"),
            });
          }
        });

        let a = [];
        filter.forEach((val, index) => {
          a.push({
            ...val,
            sr: index + 1,
          });
        });
        setShowData(a);
        setAllData(addsr);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getTicket();
    // eslint-disable-next-line
  }, []);

  const filterDataHandler = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = allData.filter((item) => {
      return (
        item.ticket_no.toLowerCase().includes(searchValue) ||
        item.vendor_name.toLowerCase().includes(searchValue) ||
        item.priority.toLowerCase().includes(searchValue) ||
        item.status.toLowerCase().includes(searchValue) ||
        item.date.toLowerCase().includes(searchValue)
      );
    });

    let a = [];
    filteredData.forEach((val, index) => {
      a.push({
        ...val,
        sr: index + 1,
      });
    });
    setShowData(a);
    setPage(0);
  };

  return (
    <Box className="mt-5 ">
      {view ? (
        <ViewTicket
          setEditData={setEditData}
          setView={setView}
          editData={editData}
          getTicket={getTicket}
          setSnackOpen={setSnackOpen}
          setSnackMsg={setSnackMsg}
        />
      ) : (
        <>
          <Box className="mb-2 d-flex justify-content-between align-items-center">
            <input
              type="search"
              placeholder="Search..."
              className="searchInput"
              onChange={filterDataHandler}
            />
          </Box>
          {loader ? (
            <CircularProgress />
          ) : (
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "10px" }}
            >
              {allData.length > 0 ? (
                <>
                  {showData.length > 0 ? (
                    <>
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      background: "var(--bs-primary-tint-80)",
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {showData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, ind) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={ind}
                                    onClick={() => {
                                      setEditData(row);
                                      setView(true);
                                    }}
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        column.id !== "action" && (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                              cursor: "pointer",
                                              color:
                                                column.id === "priority"
                                                  ? value.toLowerCase() ===
                                                    "urgent"
                                                    ? "red"
                                                    : value.toLowerCase() ===
                                                      "high"
                                                    ? "#ff8400"
                                                    : value.toLowerCase() ===
                                                      "medium"
                                                    ? "blue"
                                                    : value.toLowerCase() ===
                                                      "low"
                                                    ? "green"
                                                    : "black"
                                                  : column.id === "status"
                                                  ? value.toLowerCase() ===
                                                    "canceled"
                                                    ? "red"
                                                    : value.toLowerCase() ===
                                                      "pending"
                                                    ? "#ff8400"
                                                    : value.toLowerCase() ===
                                                      "open"
                                                    ? "blue"
                                                    : value.toLowerCase() ===
                                                      "completed"
                                                    ? "green"
                                                    : "black"
                                                  : "black",
                                            }}
                                            title={value}
                                          >
                                            {column.id === "logo_image" &&
                                            value !== null ? (
                                              <img
                                                style={{
                                                  maxWidth: "80px",
                                                  minWidth: "auto",
                                                  minHeight: "auto",
                                                  maxHeight: "80px",
                                                  margin: "0 auto",
                                                  border: "1px solid #adadad",
                                                  padding: "5px",
                                                }}
                                                alt="imageTickets"
                                                src={`${REACT_APP_BASE_URL.replace(
                                                  "/api",
                                                  ""
                                                )}/${value}`}
                                              />
                                            ) : column.format &&
                                              typeof value === "number" ? (
                                              column.format(value)
                                            ) : (
                                              value
                                            )}
                                          </TableCell>
                                        )
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={showData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <Box className="text-danger p-3">Ticket Not Found...</Box>
                  )}
                </>
              ) : (
                <Box className="text-danger p-3">
                  Ticket Not Available, Please Add...
                </Box>
              )}
            </Paper>
          )}
        </>
      )}

      <SnackbarToast
        snackMsg={snackMsg}
        open={snackOpen}
        setOpen={setSnackOpen}
      />
    </Box>
  );
}
