import React, { useEffect, useState } from "react";
import { Row, Col, Image, Form, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";

// img
// import facebook from "../../../assets/images/brands/fb.svg";
// import google from "../../../assets/images/brands/gm.svg";
// import instagram from "../../../assets/images/brands/im.svg";
// import linkedin from "../../../assets/images/brands/li.svg";
import auth1 from "../../../assets/images/auth/01.png";
import axios from "axios";
import REACT_APP_BASE_URL from "../../../utilities/api";
import ToastHandler from "../../common/ToastHandler";
import Logo from "../../../components/partials/components/logo";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignIn = () => {
  // let history = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [apiErr, setApiErr] = useState("");
  const [vara, setVara] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // const toggleshowToast = () => setShowToast(!showToast);

  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
        setError("");
        setVara("");
      }, [30000]);
    }
  }, [showToast]);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setError((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  const onLoginHandler = () => {
    if (input.email === "") {
      setError((prev) => {
        return { ...prev, email: "Email Id is required..." };
      });
    }
    if (input.password === "") {
      setError((prev) => {
        return { ...prev, password: "Password is required..." };
      });
    }
    if (input.email !== "" && input.password !== "") {
      axios
        .post(`${REACT_APP_BASE_URL}/admin/login`, {
          username: input.email,
          password: input.password,
        })
        .then(function (response) {
          document.cookie = `eventsGoToken=${response.data.token}`;
          localStorage.setItem(
            "emsLoginData",
            JSON.stringify(response.data.data)
          );
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          if (
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.error !== undefined
          ) {
            setShowToast(true);
            setApiErr(error.response.data.error);
            setVara("error");
          } else {
            setShowToast(true);
            setApiErr(error);
            setVara("error");
          }
        });
    }
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <Link
                      to="/"
                      className="navbar-brand d-flex align-items-center justify-content-center mb-3"
                    >
                      <Logo />
                    </Link>
                    <h2 className="mb-2 text-center">Sign In</h2>
                    <p className="text-center">Login to stay connected.</p>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder=" "
                              onChange={onChangeHandler}
                              name="email"
                              isInvalid={error.email !== "" ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          {" "}
                          <Form.Label htmlFor="password" className="">
                            Password
                          </Form.Label>
                          <InputGroup className="form-group">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className=""
                              id="password"
                              aria-describedby="password"
                              placeholder=" "
                              onChange={onChangeHandler}
                              isInvalid={error.password !== "" ? true : false}
                            />
                            <InputGroup.Text>
                              {showPassword ? (
                                <VisibilityOff
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <Visibility
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {error.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-between">
                          {/* <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Remember Me
                            </Form.Check.Label>
                          </Form.Check> */}
                          <Link to="/auth/recoverpw">Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          onClick={onLoginHandler}
                          type="button"
                          variant="btn btn-primary"
                        >
                          Sign In
                        </Button>
                      </div>

                      {/* <p className="text-center my-3">
                        or sign in with other accounts?
                      </p>
                      <div className="d-flex justify-content-center">
                        <ListGroup
                          as="ul"
                          className="list-group-horizontal list-group-flush"
                        >
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <Link to="#">
                              <Image src={facebook} alt="fb" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <Link to="#">
                              <Image src={google} alt="gm" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <Link to="#">
                              <Image src={instagram} alt="im" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <Link to="#">
                              <Image src={linkedin} alt="li" />
                            </Link>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                      <p className="mt-3 text-center">
                        Don’t have an account?{" "}
                        <Link to="/auth/sign-up" className="text-underline">
                          Click here to sign up.
                        </Link>
                      </p> */}
                    </Form>
                  </Card.Body>
                  {showToast && (
                    <ToastHandler
                      showToast={showToast}
                      setShowToast={setShowToast}
                      apiErr={apiErr}
                      vara={vara}
                    />
                  )}
                </Card>
              </Col>
            </Row>
            <div className="sign-bg">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignIn;
