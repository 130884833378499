import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../utilities/api";
import axios from "axios";
import tokenHandler from "../../utilities/tokenHandler";
import { InputGroup } from "react-bootstrap";
import { Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AddEditSupport({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getSupport,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          company_name: JSON.parse(localStorage.getItem("emsLoginData"))
            .company_name,
          address: "",
          authorized_person: "",
          authorized_person_mob_no: "",
          authorized_person_email: "",
          password: "",
          role: "support",
        }
  );
  const [error, setError] = useState({
    address: "",
    authorized_person: "",
    authorized_person_mob_no: "",
    authorized_person_email: "",
    password: "",
  });
  const token = tokenHandler();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;

    setInput((prev) => {
      return { ...prev, [name]: value };
    });

    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.address === "") {
      setError((prev) => {
        return { ...prev, address: "Address is required..." };
      });
    }
    if (input.authorized_person === "") {
      setError((prev) => {
        return { ...prev, authorized_person: "Name is required..." };
      });
    }
    if (input.authorized_person_mob_no === "") {
      setError((prev) => {
        return {
          ...prev,
          authorized_person_mob_no: "Mobile Number is required...",
        };
      });
    }
    if (input.authorized_person_email === "") {
      setError((prev) => {
        return { ...prev, authorized_person_email: "Email Id is required..." };
      });
    }
    if (input.password === "") {
      setError((prev) => {
        return { ...prev, password: "Password is required..." };
      });
    }

    if (
      input.address !== "" &&
      input.authorized_person !== "" &&
      input.authorized_person_mob_no !== "" &&
      input.authorized_person_email !== "" &&
      input.password !== ""
    ) {
      if (isEdit) {
        await axios
          .put(
            `${REACT_APP_BASE_URL}/admin/${input.id}`,
            { ...input, company_email: input.authorized_person_email },
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then(() => {
            setShowModal(false);
            getSupport();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Support Team Member Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              company_name: JSON.parse(localStorage.getItem("emsLoginData"))
                .company_name,
              address: "",
              authorized_person: "",
              authorized_person_mob_no: "",
              authorized_person_email: "",
              password: "",
              role: "support",
            });
            setError({
              address: "",
              authorized_person: "",
              authorized_person_mob_no: "",
              authorized_person_email: "",
              password: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(
            `${REACT_APP_BASE_URL}/admin`,
            { ...input, company_email: input.authorized_person_email },
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then(() => {
            setShowModal(false);
            getSupport();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Support Team Member Added Successfully.",
              variant: "success",
            });
            setInput({
              company_name: JSON.parse(localStorage.getItem("emsLoginData"))
                .company_name,
              address: "",
              authorized_person: "",
              authorized_person_mob_no: "",
              authorized_person_email: "",
              password: "",
              role: "support",
            });
            setError({
              address: "",
              authorized_person: "",
              authorized_person_mob_no: "",
              authorized_person_email: "",
              password: "",
            });
          })
          .catch((error) => {
            console.log(error);
            if (
              error.response.data !== undefined &&
              error.response.data.msg !== undefined
            ) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          company_name: JSON.parse(localStorage.getItem("emsLoginData"))
            .company_name,
          address: "",
          authorized_person: "",
          authorized_person_mob_no: "",
          authorized_person_email: "",
          password: "",
          role: "support",
        });
        setError({
          address: "",
          authorized_person: "",
          authorized_person_mob_no: "",
          authorized_person_email: "",
          password: "",
        });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Update Support Team Member" : "Add Support Team Member"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3 ">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="authorized_person"
              onChange={onChangeHandler}
              value={input.authorized_person}
              placeholder="Enter Name"
              isInvalid={error.authorized_person !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.authorized_person}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 ">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name="authorized_person_mob_no"
              type="number"
              onChange={onChangeHandler}
              value={input.authorized_person_mob_no}
              placeholder="Enter Mobile Number"
              isInvalid={error.authorized_person_mob_no !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.authorized_person_mob_no}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 ">
            <Form.Label>Email Id</Form.Label>
            <Form.Control
              name="authorized_person_email"
              type="email"
              onChange={onChangeHandler}
              value={input.authorized_person_email}
              placeholder="Enter Email Id"
              isInvalid={error.authorized_person_email !== "" ? true : false}
            />{" "}
            <Form.Control.Feedback type="invalid">
              {error.authorized_person_email}
            </Form.Control.Feedback>
          </Form.Group>
          {!isEdit && (
            <Box className="mb-3">
            <Form.Label>Password</Form.Label>

            <InputGroup>
              <Form.Control
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={onChangeHandler}
                value={input.password}
                placeholder="Enter Password"
                isInvalid={error.password !== "" ? true : false}
              />
              <InputGroup.Text>
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {error.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Box>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              name="address"
              onChange={onChangeHandler}
              value={input.address}
              isInvalid={error.address !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.address}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditSupport;
