import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../../utilities/api";
import axios from "axios";
import tokenHandler from "../../../utilities/tokenHandler";

function AddEditGreetingType({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getGreetingType,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          type: "",
        }
  );
  const [error, setError] = useState({
    type: "",
  });
  const token = tokenHandler();

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.type === "") {
      setError((prev) => {
        return { ...prev, type: "Greeting Type is required..." };
      });
    } else {
      if (isEdit) {
        await axios
          .put(`${REACT_APP_BASE_URL}/greetingType/${input.id}`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getGreetingType();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Greeting Type Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setInput({
              type: "",
            });
            setError({ type: "" });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(`${REACT_APP_BASE_URL}/greetingType`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getGreetingType();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Greeting Type Added Successfully.",
              variant: "success",
            });
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              type: "",
            });
            setError({ type: "" });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          type: "",
        });
        setError({ type: "" });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Update Greeting Type" : "Add Greeting Type"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Greeting Type</Form.Label>
            <Form.Control
              name="type"
              onChange={onChangeHandler}
              value={input.type}
              placeholder="Enter Greeting Type"
              autoFocus
              isInvalid={error.type !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.type}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditGreetingType;
