import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../../utilities/api";
import axios from "axios";
import tokenHandler from "../../../utilities/tokenHandler";

function AddEditServices({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getServices,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          service_name: "",
          description: "",
        }
  );
  const [error, setError] = useState({
    service_name: "",
  });
  const token = tokenHandler();

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.service_name === "") {
      setError((prev) => {
        return { ...prev, service_name: "Service Name is required..." };
      });
    } else {
      if (isEdit) {
        await axios
          .put(`${REACT_APP_BASE_URL}/service/${input.id}`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getServices();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Service Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setInput({
              service_name: "",
              description: "",
            });
            setError({ service_name: "" });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(`${REACT_APP_BASE_URL}/service`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getServices();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Service Added Successfully.",
              variant: "success",
            });
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              service_name: "",
              description: "",
            });
            setError({ service_name: "" });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          service_name: "",
          description: "",
        });
        setError({ service_name: "" });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Update Service" : "Add Service"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Service Name</Form.Label>
            <Form.Control
              name="service_name"
              onChange={onChangeHandler}
              value={input.service_name}
              placeholder="Enter Service Name"
              autoFocus
              isInvalid={error.service_name !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.service_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={onChangeHandler}
              value={input.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditServices;
