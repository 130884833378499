// use to get the token from cookie
const tokenHandler = () => {
    let tkn = "";
    document.cookie.split("; ").forEach((v) => {
      if (v.split("=")[0] === "eventsGoToken") {
        tkn = v.split("=")[1];
      }
    });
    return tkn;
  };
  
  export default tokenHandler;
  