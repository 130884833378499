import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../../utilities/api";
import axios from "axios";
import tokenHandler from "../../../utilities/tokenHandler";

function AddEditPackages({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getPackages,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [services, setServices] = useState([]);
  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          package_name: "",
          service_id: "",
          validity_in_days: "",
          amount: "",
          description: "",
        }
  );
  const [error, setError] = useState({
    package_name: "",
    service_id: "",
    validity_in_days: "",
    amount: "",
  });
  const token = tokenHandler();

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}/service`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        const activePackages = response.data.data.filter(
          (pkg) => pkg.is_active
        );
        setServices(activePackages);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.service_id === "") {
      setError((prev) => {
        return { ...prev, service_id: "Service Name is required..." };
      });
    }
    if (input.package_name === "") {
      setError((prev) => {
        return { ...prev, package_name: "Package Name is required..." };
      });
    }
    if (input.validity_in_days === "") {
      setError((prev) => {
        return { ...prev, validity_in_days: "Validity is required..." };
      });
    }
    if (input.amount === "") {
      setError((prev) => {
        return { ...prev, amount: "Amount is required..." };
      });
    }
    if (
      input.service_id !== "" &&
      input.package_name !== "" &&
      input.validity_in_days !== "" &&
      input.amount !== ""
    ) {
      if (isEdit) {
        await axios
          .put(`${REACT_APP_BASE_URL}/service/servicePkg/${input.id}`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getPackages();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Package Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              package_name: "",
              service_id: "",
              validity_in_days: "",
              amount: "",
              description: "",
            });
            setError({
              package_name: "",
              service_id: "",
              validity_in_days: "",
              amount: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(`${REACT_APP_BASE_URL}/service/servicePkg`, input, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getPackages();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Package Added Successfully.",
              variant: "success",
            });
            setInput({
              package_name: "",
              service_id: "",
              validity_in_days: "",
              amount: "",
              description: "",
            });
            setError({
              package_name: "",
              service_id: "",
              validity_in_days: "",
              amount: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          package_name: "",
          service_id: "",
          validity_in_days: "",
          amount: "",
          description: "",
        });
        setError({
          package_name: "",
          service_id: "",
          validity_in_days: "",
          amount: "",
        });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Update Package" : "Add Package"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Select Service</Form.Label>
            <Form.Select
              name="service_id"
              onChange={onChangeHandler}
              value={input.service_id}
              isInvalid={error.service_id !== "" ? true : false}
            >
              <option value="">Select Service</option>{" "}
              {/* Default placeholder */}
              <option value="All Service">All Service</option>
              {services.map((v, inx) => {
                return (
                  <option value={v.id} key={inx}>
                    {v.service_name}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {error.service_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Package Name</Form.Label>
            <Form.Control
              name="package_name"
              onChange={onChangeHandler}
              value={input.package_name}
              placeholder="Enter Package Name"
              autoFocus
              isInvalid={error.package_name !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.package_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Validity (days)</Form.Label>
            <Form.Control
              type="number"
              name="validity_in_days"
              onChange={onChangeHandler}
              value={input.validity_in_days}
              placeholder="Enter Validity in days"
              autoFocus
              isInvalid={error.validity_in_days !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.validity_in_days}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              name="amount"
              type="number"
              onChange={onChangeHandler}
              value={input.amount}
              placeholder="Enter Amount"
              autoFocus
              isInvalid={error.amount !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.amount}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={onChangeHandler}
              value={input.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditPackages;
