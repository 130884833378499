import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../../utilities/api";
import axios from "axios";
import tokenHandler from "../../../utilities/tokenHandler";

function AddEditGreeting({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getGreeting,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [greetingType, setGreetingType] = useState([]);
  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          greeting_name: "",
          image: "",
          description: "",
          greeting_type_id: "",
        }
  );
  const [error, setError] = useState({
    greeting_name: "",
    image: "",
    greeting_type_id: "",
  });
  const token = tokenHandler();

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}/greetingType`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        const activeGreetingType = response.data.data.filter(
          (dta) => dta.is_active
        );
        setGreetingType(activeGreetingType);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value, files } = e.target;
    if (name === "image") {
      setInput((prev) => {
        return { ...prev, [name]: files[0] };
      });
    } else {
      setInput((prev) => {
        return { ...prev, [name]: value };
      });
    }

    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.greeting_type_id === "") {
      setError((prev) => {
        return { ...prev, greeting_type_id: "Greeting Type is required..." };
      });
    }
    if (input.greeting_name === "") {
      setError((prev) => {
        return { ...prev, greeting_name: "Greeting Name is required..." };
      });
    }
    if (input.image === "") {
      setError((prev) => {
        return { ...prev, image: "Image is required..." };
      });
    }

    if (
      input.greeting_type_id !== "" &&
      input.greeting_name !== "" &&
      input.image !== ""
    ) {
      var form_data = new FormData();

      for (var key in input) {
        form_data.append(key, input[key]);
      }
      if (isEdit) {
        await axios
          .put(`${REACT_APP_BASE_URL}/greeting/${input.id}`, form_data, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getGreeting();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Greeting Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              greeting_name: "",
              image: "",
              description: "",
              greeting_type_id: "",
            });
            setError({
              greeting_name: "",
              image: "",
              greeting_type_id: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(`${REACT_APP_BASE_URL}/greeting`, form_data, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getGreeting();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Greeting Added Successfully.",
              variant: "success",
            });
            setInput({
              greeting_name: "",
              image: "",
              description: "",
              greeting_type_id: "",
            });
            setError({
              greeting_name: "",
              image: "",
              greeting_type_id: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          greeting_name: "",
          image: "",
          description: "",
          greeting_type_id: "",
        });
        setError({
          greeting_name: "",
          image: "",
          greeting_type_id: "",
        });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Update Greeting" : "Add Greeting"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Select Greeting Type</Form.Label>
            <Form.Select
              name="greeting_type_id"
              onChange={onChangeHandler}
              value={input.greeting_type_id}
              isInvalid={error.greeting_type_id !== "" ? true : false}
            >
              <option value="">Select Greeting Type</option>{" "}
              {/* Default placeholder */}
              {greetingType.map((v, inx) => {
                return (
                  <option value={v.id} key={inx}>
                    {v.type}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {error.greeting_type_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Greeting Name</Form.Label>
            <Form.Control
              name="greeting_name"
              onChange={onChangeHandler}
              value={input.greeting_name}
              placeholder="Enter Greeting Name"
              autoFocus
              isInvalid={error.greeting_name !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.greeting_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control
              name="image"
              type="file"
              accept="image/*"
              onChange={onChangeHandler}
              autoFocus
              isInvalid={error.image !== "" ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {error.image}
            </Form.Control.Feedback>
            {input.image !== "" &&
            input.image !== null &&
            typeof input.image === "string" ? (
              <img
                style={{
                  maxWidth: "80px",
                  minWidth: "auto",
                  minHeight: "auto",
                  maxHeight: "80px",
                  margin: "10px auto",
                  border: "1px solid #adadad",
                  padding: "5px",
                }}
                alt="imageGreeting"
                src={`${REACT_APP_BASE_URL.replace("/api", "")}/${input.image}`}
              />
            ) : (
              input.image !== "" &&
              input.image !== null && (
                <img
                  style={{
                    maxWidth: "80px",
                    minWidth: "auto",
                    minHeight: "auto",
                    maxHeight: "80px",
                    margin: "10px auto",
                    border: "1px solid #adadad",
                    padding: "5px",
                  }}
                  alt="imageGreeting"
                  src={URL.createObjectURL(input.image)}
                />
              )
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={onChangeHandler}
              value={input.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditGreeting;
