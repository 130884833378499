import React from "react";
import { Alert, Snackbar } from "@mui/material";

export default function SnackbarToast({ snackMsg, open, setOpen }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000}>
      <Alert
        variant="filled"
        severity={snackMsg.variant}
        sx={{ width: "100%" }}
        onClose={handleClose}
      >
        {snackMsg.msg}
      </Alert>
    </Snackbar>
  );
}
