import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tokenHandler from "../../utilities/tokenHandler";
import REACT_APP_BASE_URL from "../../utilities/api";

function DeleteActive({
  whichPage,
  showDelAc,
  setShowDelAc,
  editData,
  getData,
  setSnackMsg,
  setSnackOpen,
  isEdit,
  setIsEdit,
  apiEndPt,
  varName,
}) {
  const token = tokenHandler();

  const onSubmitHandler = async () => {
    if (isEdit) {
      await axios
        .put(
          `${REACT_APP_BASE_URL}/${apiEndPt}/${editData.id}`,
          {
            ...editData,
            is_active: !editData.is_active,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then(() => {
          setShowDelAc(false);
          getData();
          setSnackOpen(true);
          setSnackMsg({
            msg: `${whichPage} ${
              editData.is_active ? "Deactive" : "Activate"
            } Successfully.`,
            variant: "success",
          });
          setIsEdit(false);
        })
        .catch((error) => {
          if (error.response.data.msg !== undefined) {
            setSnackOpen(true);
            setSnackMsg({
              msg: error.response.data.msg,
              variant: "error",
            });
          } else {
            setSnackOpen(true);
            setSnackMsg({
              msg: `Something went wrong. Please try later!!`,
              variant: "error",
            });
          }
        });
    } else {
      await axios
        .put(
          `${REACT_APP_BASE_URL}/${apiEndPt}/${editData.id}`,
          {
            ...editData,
            is_delete: true,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then(() => {
          setShowDelAc(false);
          getData();
          setSnackOpen(true);
          setSnackMsg({
            msg: `${whichPage} Delete Successfully.`,
            variant: "success",
          });
          setIsEdit(false);
        })
        .catch((error) => {
          if (error.response.data.msg !== undefined) {
            setSnackOpen(true);
            setSnackMsg({
              msg: error.response.data.msg,
              variant: "error",
            });
          } else {
            setSnackOpen(true);
            setSnackMsg({
              msg: `Something went wrong. Please try later!!`,
              variant: "error",
            });
          }
        });
    }
  };
  return (
    <>
      <Modal
        show={showDelAc}
        onHide={() => {
          setShowDelAc(false);
          setIsEdit(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit
              ? editData.is_active
                ? "Deactivate"
                : "Activate"
              : "Delete"}{" "}
            {whichPage}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do You Want To{" "}
          {isEdit ? (editData.is_active ? "Deactivate" : "Activate") : "Delete"}{" "}
          {editData[varName]} {whichPage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={isEdit && !editData.is_active ? "success" : "danger"}
            onClick={onSubmitHandler}
          >
            {isEdit
              ? editData.is_active
                ? "Deactivate"
                : "Activate"
              : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteActive;
