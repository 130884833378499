import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import AddEditVendor from "./AddEditVendor";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import axios from "axios";
import REACT_APP_BASE_URL from "../../utilities/api";
import tokenHandler from "../../utilities/tokenHandler";
import SnackbarToast from "../common/SnackbarToast";
import DeleteActive from "../common/DeleteActive";
import ViewVendor from "./ViewVendor";

const columns = [
  { id: "sr", label: "Sr. No", align: "center" },
  { id: "company_name", label: "Company Name", align: "center" },
  { id: "owner_name", label: "Owner Name", align: "center" },

  { id: "service_name", label: "Service", align: "center" },
  { id: "mob_no", label: "Mobile No", align: "center" },
  { id: "pincode", label: "Pincode", align: "center" },
  { id: "address", label: "Address", align: "center" },
  { id: "registration_date", label: "Registration Date", align: "center" },

  { id: "logo_image", label: "Image", align: "center" },

  {
    id: "action",
    label: "Action",
    align: "center",
  },
];

export default function Vendor() {
  const [showModal, setShowModal] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allData, setAllData] = React.useState([]);
  const [showData, setShowData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isEdit, setIsEdit] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [showDelAc, setShowDelAc] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState({ msg: "", variant: "" });
  const [editData, setEditData] = React.useState({});

  const token = tokenHandler();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (snackOpen) {
      setTimeout(() => {
        setSnackOpen(false);
        setSnackMsg({ msg: "", variant: "" });
      }, [10000]);
    }
  }, [snackOpen]);

  const getVendor = async () => {
    setLoader(true);
    await axios
      .get(`${REACT_APP_BASE_URL}/vendor`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        let addsr = [];
        response.data.vendor.forEach((val, index) => {
          addsr.push({
            ...val,
            sr: index + 1,
            service_name: val.service.service_name,
          });
        });
        setShowData(addsr);
        setAllData(addsr);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getVendor();
    // eslint-disable-next-line
  }, []);

  const filterDataHandler = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = allData.filter((item) => {
      return (
        item.service_name.toLowerCase().includes(searchValue) ||
        item.company_name.toLowerCase().includes(searchValue) ||
        item.owner_name.toLowerCase().includes(searchValue) ||
        item.pincode.toLowerCase().includes(searchValue) ||
        item.mob_no.toLowerCase().includes(searchValue) ||
        item.registration_date.toLowerCase().includes(searchValue)
      );
    });
    setShowData(filteredData);
    setPage(0);
  };

  return (
    <Box className="mt-5 ">
      <Box className="mb-2 d-flex justify-content-between align-items-center">
        {JSON.parse(localStorage.getItem("emsLoginData")).role === "admin" && (
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
          >
            + Add
          </Button>
        )}
        <input
          type="search"
          placeholder="Search..."
          className="searchInput"
          onChange={filterDataHandler}
        />
      </Box>
      {loader ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden", marginBottom: "10px" }}>
          {allData.length > 0 ? (
            <>
              {showData.length > 0 ? (
                <>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  background: "var(--bs-primary-tint-80)",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, ind) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={ind}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    column.id !== "action" && (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                          maxWidth:
                                            column.id === "address" && "120px",
                                          overflow:
                                            column.id === "address" && "hidden",
                                          cursor: "pointer",
                                        }}
                                        title={value}
                                      >
                                        {column.id === "logo_image" &&
                                        value !== null ? (
                                          <img
                                            style={{
                                              maxWidth: "80px",
                                              minWidth: "auto",
                                              minHeight: "auto",
                                              maxHeight: "80px",
                                              margin: "0 auto",
                                              border: "1px solid #adadad",
                                              padding: "5px",
                                            }}
                                            alt="imageVendors"
                                            src={`${REACT_APP_BASE_URL.replace(
                                              "/api",
                                              ""
                                            )}/${value}`}
                                          />
                                        ) : column.format &&
                                          typeof value === "number" ? (
                                          column.format(value)
                                        ) : (
                                          value
                                        )}
                                      </TableCell>
                                    )
                                  );
                                })}
                                <TableCell align="center">
                                  {JSON.parse(
                                    localStorage.getItem("emsLoginData")
                                  ).role === "admin" && (
                                    <IconButton
                                      onClick={() => {
                                        setEditData(row);
                                        setIsEdit(true);
                                        setShowModal(true);
                                      }}
                                    >
                                      <Edit color="primary" fontSize="small" />
                                    </IconButton>
                                  )}
                                  {JSON.parse(
                                    localStorage.getItem("emsLoginData")
                                  ).role === "admin" && (
                                    <IconButton
                                      onClick={() => {
                                        setEditData(row);
                                        setIsEdit(true);
                                        setShowDelAc(true);
                                      }}
                                    >
                                      <Switch
                                        size="small"
                                        checked={
                                          row.is_active === 1 ? true : false
                                        }
                                        // onChange={handleChange}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        color="success"
                                      />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() => {
                                      setEditData(row);
                                      setView(true);
                                    }}
                                  >
                                    <Visibility
                                      color="secondary"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                  {JSON.parse(
                                    localStorage.getItem("emsLoginData")
                                  ).role === "admin" && (
                                    <IconButton
                                      onClick={() => {
                                        setEditData(row);
                                        setShowDelAc(true);
                                      }}
                                    >
                                      <Delete color="error" fontSize="small" />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={showData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <Box className="text-danger p-3">Vendor Not Found...</Box>
              )}
            </>
          ) : (
            <Box className="text-danger p-3">
              Vendor Not Available, Please Add...
            </Box>
          )}
        </Paper>
      )}

      {showModal && (
        <AddEditVendor
          getVendor={getVendor}
          showModal={showModal}
          setShowModal={setShowModal}
          isEdit={isEdit}
          setSnackMsg={setSnackMsg}
          setSnackOpen={setSnackOpen}
          setIsEdit={setIsEdit}
          editData={editData}
          setPage={setPage}
          showData={showData}
          rowsPerPage={rowsPerPage}
        />
      )}

      {showDelAc && (
        <DeleteActive
          getData={getVendor}
          showDelAc={showDelAc}
          setShowDelAc={setShowDelAc}
          setSnackMsg={setSnackMsg}
          setSnackOpen={setSnackOpen}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          editData={editData}
          apiEndPt="vendor"
          whichPage="Vendor"
          varName="company_name"
        />
      )}

      {view && <ViewVendor view={view} setView={setView} editData={editData} />}

      <SnackbarToast
        snackMsg={snackMsg}
        open={snackOpen}
        setOpen={setSnackOpen}
      />
    </Box>
  );
}
