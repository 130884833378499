import { Toast } from "react-bootstrap";

const ToastHandler = ({ showToast, setShowToast, vara, apiErr }) => {
  return (
    <Toast
      show={showToast}
      onClose={() => {
        setShowToast(false);
      }}
      style={{ background: vara === "success" ? "green" : "#be0000" }}
    >
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">Error</strong>
      </Toast.Header>
      <Toast.Body style={{ color: "white" }}>{apiErr}</Toast.Body>
    </Toast>
  );
};

export default ToastHandler;
