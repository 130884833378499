import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../utilities/api";

function ViewSupport({ setView, view, editData }) {
  return (
    <Modal
      size="xl"
      show={view}
      onHide={() => {
        setView(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Support Team Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container  h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col  mb-4 mb-lg-0">
              <div className="card mb-1" style={{ borderRadius: ".5rem" }}>
                <div className="row g-0">
                  <div
                    className="col-lg-4 gradient-custom text-center d-flex flex-column align-items-center justify-content-center "
                    style={{
                      borderTopLeftRadius: ".5rem",
                      borderBottomLeftRadius: ".5rem",
                    }}
                  >
                 
                    <h5>{editData.authorized_person}</h5>
                    <p>{editData.company_name}</p>
                    <i className="far fa-edit mb-5"></i>
                  </div>
                  <div className="col-lg-8">
                    <div className="card-body p-4">
                      <h6>Personal Information</h6>
                      <hr className="mt-0 mb-4" />
                      <div className="row pt-1">
                        <div className="col-lg-6 mb-1">
                          <h6>Mobile Number</h6>
                          <p className="text-muted">
                            {editData.authorized_person_mob_no}
                          </p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>Email Id</h6>
                          <p className="text-muted">
                            {editData.authorized_person_email}
                          </p>
                        </div>

                        <div className="col-lg-12 mb-1">
                          <h6>Address</h6>
                          <p className="text-muted">{editData.address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ViewSupport;
