import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../utilities/api";

function ViewVendor({ setView, view, editData }) {
  return (
    <Modal
      size="xl"
      show={view}
      onHide={() => {
        setView(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Vendor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container  h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col  mb-4 mb-lg-0">
              <div className="card mb-1" style={{ borderRadius: ".5rem" }}>
                <div className="row g-0">
                  <div
                    className="col-lg-4 gradient-custom text-center d-flex flex-column align-items-center justify-content-center "
                    style={{
                      borderTopLeftRadius: ".5rem",
                      borderBottomLeftRadius: ".5rem",
                    }}
                  >
                    {editData.logo_image !== null && (
                      <img
                        src={`${REACT_APP_BASE_URL.replace("/api", "")}/${
                          editData.logo_image
                        }`}
                        alt="Avatar"
                        className="img-fluid my-5"
                        style={{
                          maxWidth: "150px",
                          minWidth: "auto",
                          maxHeight: "150px",
                          minHeight: "auto",
                        }}
                      />
                    )}
                    <h5>{editData.owner_name}</h5>
                    <p>{editData.company_name}</p>
                    <i className="far fa-edit mb-5"></i>
                  </div>
                  <div className="col-lg-8">
                    <div className="card-body p-4">
                      <h6>Personal Information</h6>
                      <hr className="mt-0 mb-4" />
                      <div className="row pt-1">
                        <div className="col-lg-6 mb-1">
                          <h6>Mobile Number</h6>
                          <p className="text-muted">{editData.mob_no}</p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>Alternate Mobile Number</h6>
                          <p className="text-muted">{editData.alt_mob_no}</p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>Pincode</h6>
                          <p className="text-muted">{editData.pincode}</p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>City</h6>
                          <p className="text-muted">{editData.city_id}</p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>State</h6>
                          <p className="text-muted">{editData.state_id}</p>
                        </div>
                        <div className="col-lg-6 mb-1">
                          <h6>Country</h6>
                          <p className="text-muted">{editData.country_id}</p>
                        </div>
                        <div className="col-lg-12 mb-1">
                          <h6>Address</h6>
                          <p className="text-muted">{editData.address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* <h6></h6> */}
                    <hr className="mt-0 mb-4" />
                    <div className="row pt-1" style={{ padding: "20px" }}>
                      <div className="col-lg-4 mb-1">
                        <h6>Service</h6>
                        <p className="text-muted">{editData.service_name}</p>
                      </div>
                      <div className="col-lg-4 mb-1">
                        <h6>Registration Date</h6>
                        <p className="text-muted">
                          {editData.registration_date}
                        </p>
                      </div>
                      <div className="col-lg-4 mb-1">
                        <h6>Start Date</h6>
                        <p className="text-muted">
                          {new Date(editData.createdAt)
                            .toLocaleDateString("fr-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .split("-")
                            .reverse()
                            .join("-")}
                        </p>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-start">
                      <a href="#!">
                        <i className="fab fa-facebook-f fa-lg me-3"></i>
                      </a>
                      <a href="#!">
                        <i className="fab fa-twitter fa-lg me-3"></i>
                      </a>
                      <a href="#!">
                        <i className="fab fa-instagram fa-lg"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ViewVendor;
