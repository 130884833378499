import REACT_APP_BASE_URL from "../../utilities/api";
import { Form } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import tokenHandler from "../../utilities/tokenHandler";
import { ArrowBack, Reply } from "@mui/icons-material";
import { Button } from "@mui/material";

import ava from "../../assets/images/avatars/ava.png";
import ava1 from "../../assets/images/avatars/01.png";

function ViewTicket({
  setView,
  setEditData,
  editData,
  setSnackOpen,
  setSnackMsg,
  getTicket,
}) {
  const [input, setInput] = useState({
    message: "",
    support_id: JSON.parse(localStorage.getItem("emsLoginData")).id,
    ticket_id: editData.id,
    status: editData.status,
  });
  const [error, setError] = useState({
    message: "",
  });
  const token = tokenHandler();
  const [reply, setReply] = useState(false);

  const onChangeHandler = (e) => {
    let { name, value } = e.target;

    setInput((prev) => {
      return { ...prev, [name]: value };
    });

    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.message === "") {
      setError((prev) => {
        return { ...prev, message: "Message is required..." };
      });
    }

    if (input.message !== "") {
      await axios
        .post(`${REACT_APP_BASE_URL}/ticket/reply`, input, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(() => {
          setReply(false);
          getTicket();
          setSnackOpen(true);
          setSnackMsg({
            msg: "Your message submitted successfully!.",
            variant: "success",
          });
          setEditData((prev) => {
            return { ...prev, status: input.status };
          });
          setInput({
            message: "",
            support_id: JSON.parse(localStorage.getItem("emsLoginData")).id,
            ticket_id: editData.id,
            status: editData.status,
          });
          setError({
            message: "",
          });
        })
        .catch((error) => {
          console.log(error);
          setSnackOpen(true);
          setSnackMsg({
            msg: `Something went wrong. Please try later!!`,
            variant: "error",
          });
        });
    }
  };

  return (
    <div className="container padding-bottom-3x mb-2">
      <div className="row">
        <div>
          <div className="padding-top-2x mt-2 hidden-lg-up"></div>
          <Button
            color="primary"
            variant="contained"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setView(false);
            }}
            className="mb-4"
          >
            <ArrowBack style={{ marginRight: "5px" }} /> Back To All Ticket
          </Button>
          <h5 className="text-primary mb-2">
            {editData.vendor_name} (Ticket No: {editData.ticket_no})
          </h5>
          <h6>Subject: {editData.subject}</h6>
          <hr />

          {!reply ? (
            <Button
              color="primary"
              variant="contained"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                setReply(true);
              }}
            >
              <Reply style={{ marginRight: "5px" }} /> Reply
            </Button>
          ) : (
            <>
              <h5 className="mb-30 py-3">Reply</h5>
              <div className="form-group">
                <Form>
                  <Form.Group className="mb-3 ">
                    <Form.Control
                      name="message"
                      onChange={onChangeHandler}
                      placeholder="Write Your Message..."
                      value={input.message}
                      isInvalid={error.message !== "" ? true : false}
                      as="textarea"
                      rows={4}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ width: "250px" }}>
                    <Form.Label>Change Status</Form.Label>
                    <Form.Select
                      name="status"
                      onChange={onChangeHandler}
                      value={input.status}
                    >
                      <option value="Open" style={{ color: "blue" }}>
                        Open
                      </option>
                      <option value="Pending" style={{ color: "#ff8400" }}>
                        Pending
                      </option>
                      <option value="Canceled" style={{ color: "red" }}>
                        Canceled
                      </option>
                      <option value="Completed" style={{ color: "green" }}>
                        Completed
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-outline-primary"
                  onClick={onSubmitHandler}
                >
                  Send Message
                </button>
              </div>
              <hr />
            </>
          )}

          <div className="table-responsive margin-bottom-2x">
            <table className="table margin-bottom-none border border-dark mt-3">
              <thead>
                <tr>
                  <th>Date Submitted</th>
                  <th>Last Updated</th>
                  <th>Priority</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {new Date(editData.createdAt)
                      .toLocaleDateString("fr-CA", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>
                  <td>
                    {new Date(editData.updatedAt)
                      .toLocaleDateString("fr-CA", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          editData.priority.toLowerCase() === "urgent"
                            ? "red"
                            : editData.priority.toLowerCase() === "high"
                            ? "#ff8400"
                            : editData.priority.toLowerCase() === "medium"
                            ? "blue"
                            : editData.priority.toLowerCase() === "low"
                            ? "green"
                            : "black",
                      }}
                    >
                      {editData.priority}
                    </span>
                  </td>
                  <td
                    style={{
                      color:
                        editData.status.toLowerCase() === "canceled"
                          ? "red"
                          : editData.status.toLowerCase() === "pending"
                          ? "#ff8400"
                          : editData.status.toLowerCase() === "open"
                          ? "blue"
                          : editData.status.toLowerCase() === "completed"
                          ? "green"
                          : "black",
                    }}
                  >
                    <span>{editData.status}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="comment">
            <div className="comment-author-ava">
              <img
                src={
                  editData.vendor.logo_image === null
                    ? ava
                    : `${REACT_APP_BASE_URL.replace("/api", "")}/${
                        editData.vendor.logo_image
                      }`
                }
                alt="Avatar"
              />
            </div>
            <div className="comment-body">
              <p className="comment-text">{editData.message}</p>
              <div className="comment-footer">
                <div className="comment-meta" style={{ color: "black" }}>
                  {editData.vendor_name}
                </div>
                <div className="comment-meta">
                  {new Date(editData.createdAt)
                    .toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .split("-")
                    .reverse()
                    .join("-")}{" "}
                  {new Date(editData.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </div>
              </div>
            </div>
          </div>
          {editData.reply.map((v, ind) => {
            return (
              <div className="comment" key={ind}>
                <div className="comment-author-ava">
                  <img
                    src={
                      v.vendor_id !== 0 && v.vendor.logo_image !== null
                        ? `${REACT_APP_BASE_URL.replace("/api", "")}/${
                            v.vendor.logo_image
                          }`
                        : v.vendor_id !== 0 && v.vendor.logo_image === null
                        ? ava
                        : ava1
                    }
                    alt="Avatar"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
                <div className="comment-body">
                  <p className="comment-text">{v.message}</p>
                  <div className="comment-footer">
                    <div className="comment-meta" style={{ color: "black" }}>
                      {v.vendor_id === 0
                        ? v.support.authorized_person
                        : v.support_id === 0
                        ? v.vendor.owner_name
                        : ""}
                    </div>
                    <div className="comment-meta">
                      {new Date(v.createdAt)
                        .toLocaleDateString("fr-CA", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .split("-")
                        .reverse()
                        .join("-")}{" "}
                      {new Date(v.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ViewTicket;
