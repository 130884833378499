import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import REACT_APP_BASE_URL from "../../utilities/api";
import axios from "axios";
import tokenHandler from "../../utilities/tokenHandler";
import { InputGroup, Row } from "react-bootstrap";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/material";

function AddEditVendor({
  setShowModal,
  showModal,
  isEdit,
  setSnackOpen,
  setSnackMsg,
  getVendor,
  editData,
  setIsEdit,
  setPage,
  showData,
  rowsPerPage,
}) {
  const [service, setService] = useState([]);

  const [input, setInput] = useState(
    isEdit
      ? editData
      : {
          company_name: "",
          owner_name: "",
          mob_no: "",
          alt_mob_no: "",
          password: "",
          pincode: "",
          city_id: "",
          state_id: "",
          country_id: "",
          registration_date: "",
          logo_image: "",
          address: "",
          service_id: "",
        }
  );
  const [error, setError] = useState({
    company_name: "",
    owner_name: "",
    mob_no: "",
    password: "",
    pincode: "",
    city_id: "",
    state_id: "",
    country_id: "",
    registration_date: "",
    address: "",
    service_id: "",
  });
  const token = tokenHandler();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}/service`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        const activeService = response.data.data.filter((dta) => dta.is_active);
        setService(activeService);
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      setInput(editData);
    }
    // eslint-disable-next-line
  }, [editData]);

  const onChangeHandler = (e) => {
    let { name, value, files } = e.target;
    if (name === "registration_date") {
      console.log(value);
      value = `${value.toString().split("-")[2]}-${
        value.toString().split("-")[1]
      }-${value.toString().split("-")[0]}`;
    }
    if (name === "logo_image") {
      setInput((prev) => {
        return { ...prev, [name]: files[0] };
      });
    } else if (name === "pincode") {
      axios
        .get(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (
            response.data[0].PostOffice !== null &&
            response.data[0].PostOffice !== undefined
          ) {
            setInput((prev) => {
              return {
                ...prev,
                country_id: response.data[0].PostOffice[0].Country,
                state_id: response.data[0].PostOffice[0].State,
                city_id: response.data[0].PostOffice[0].Region,
              };
            });
          } else {
            setInput((prev) => {
              return {
                ...prev,
                country_id: "",
                state_id: "",
                city_id: "",
              };
            });
          }
        });
      setInput((prev) => {
        return { ...prev, [name]: value };
      });
    } else {
      setInput((prev) => {
        return { ...prev, [name]: value };
      });
    }

    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const onSubmitHandler = async () => {
    if (input.service_id === "") {
      setError((prev) => {
        return { ...prev, service_id: "Service is required..." };
      });
    }
    if (input.company_name === "") {
      setError((prev) => {
        return { ...prev, company_name: "Company Name is required..." };
      });
    }
    if (input.owner_name === "") {
      setError((prev) => {
        return { ...prev, owner_name: "Owner Name is required..." };
      });
    }
    if (input.mob_no === "") {
      setError((prev) => {
        return { ...prev, mob_no: "Mobile Number is required..." };
      });
    }
    if (input.password === "") {
      setError((prev) => {
        return { ...prev, password: "Password is required..." };
      });
    }
    if (input.pincode === "") {
      setError((prev) => {
        return { ...prev, pincode: "Pincode is required..." };
      });
    }
    if (input.registration_date === "") {
      setError((prev) => {
        return {
          ...prev,
          registration_date: "Registration Date is required...",
        };
      });
    }
    if (input.address === "") {
      setError((prev) => {
        return { ...prev, address: "Address is required..." };
      });
    }

    if (
      input.service_id !== "" &&
      input.company_name !== "" &&
      input.owner_name !== "" &&
      input.mob_no !== "" &&
      input.password !== "" &&
      input.pincode !== "" &&
      input.registration_date !== "" &&
      input.address !== ""
    ) {
      var form_data = new FormData();

      for (var key in input) {
        form_data.append(key, input[key]);
      }
      if (isEdit) {
        await axios
          .put(`${REACT_APP_BASE_URL}/vendor/${input.id}`, form_data, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getVendor();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Vendor Update Successfully.",
              variant: "success",
            });
            setIsEdit(false);
            setPage(Math.floor(showData.length / rowsPerPage));
            setInput({
              company_name: "",
              owner_name: "",
              mob_no: "",
              alt_mob_no: "",
              password: "",
              pincode: "",
              city_id: "",
              state_id: "",
              country_id: "",
              registration_date: "",
              logo_image: "",
              address: "",
              service_id: "",
            });
            setError({
              company_name: "",
              owner_name: "",
              mob_no: "",
              password: "",
              pincode: "",
              city_id: "",
              state_id: "",
              country_id: "",
              registration_date: "",
              address: "",
              service_id: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      } else {
        await axios
          .post(`${REACT_APP_BASE_URL}/vendor`, form_data, {
            headers: { Authorization: "Bearer " + token },
          })
          .then(() => {
            setShowModal(false);
            getVendor();
            setSnackOpen(true);
            setSnackMsg({
              msg: "Vendor Added Successfully.",
              variant: "success",
            });
            setInput({
              company_name: "",
              owner_name: "",
              mob_no: "",
              alt_mob_no: "",
              password: "",
              pincode: "",
              city_id: "",
              state_id: "",
              country_id: "",
              registration_date: "",
              logo_image: "",
              address: "",
              service_id: "",
            });
            setError({
              company_name: "",
              owner_name: "",
              mob_no: "",
              password: "",
              pincode: "",
              city_id: "",
              state_id: "",
              country_id: "",
              registration_date: "",
              address: "",
              service_id: "",
            });
          })
          .catch((error) => {
            if (error.response.data.msg !== undefined) {
              setSnackOpen(true);
              setSnackMsg({
                msg: error.response.data.msg,
                variant: "error",
              });
            } else {
              setSnackOpen(true);
              setSnackMsg({
                msg: `Something went wrong. Please try later!!`,
                variant: "error",
              });
            }
          });
      }
    }
  };

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setInput({
          company_name: "",
          owner_name: "",
          mob_no: "",
          alt_mob_no: "",
          password: "",
          pincode: "",
          city_id: "",
          state_id: "",
          country_id: "",
          registration_date: "",
          logo_image: "",
          address: "",
          service_id: "",
        });
        setError({
          company_name: "",
          owner_name: "",
          mob_no: "",
          password: "",
          pincode: "",
          city_id: "",
          state_id: "",
          country_id: "",
          registration_date: "",
          address: "",
          service_id: "",
        });
        setIsEdit(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Update Vendor" : "Add Vendor"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Select Service</Form.Label>
              <Form.Select
                name="service_id"
                onChange={onChangeHandler}
                value={input.service_id}
                isInvalid={error.service_id !== "" ? true : false}
                autoFocus
              >
                <option value="">Select Service</option>{" "}
                {/* Default placeholder */}
                {service.map((v, inx) => {
                  return (
                    <option value={v.id} key={inx}>
                      {v.service_name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {error.service_id}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                name="company_name"
                onChange={onChangeHandler}
                value={input.company_name}
                placeholder="Enter Company Name"
                isInvalid={error.company_name !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.company_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Owner Name</Form.Label>
              <Form.Control
                name="owner_name"
                onChange={onChangeHandler}
                value={input.owner_name}
                placeholder="Enter Owner Name"
                isInvalid={error.owner_name !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.owner_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                name="mob_no"
                type="number"
                onChange={onChangeHandler}
                value={input.mob_no}
                placeholder="Enter Mobile Number"
                isInvalid={error.mob_no !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.mob_no}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Alternate Mobile Number</Form.Label>
              <Form.Control
                name="alt_mob_no"
                type="number"
                onChange={onChangeHandler}
                value={input.alt_mob_no}
                placeholder="Enter Alternate Mobile Number"
              />
            </Form.Group>
            {!isEdit && (
              <Box className="mb-3 col-md-6 col-lg-4">
                <Form.Label>Password</Form.Label>

                <InputGroup>
                  <Form.Control
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={onChangeHandler}
                    value={input.password}
                    placeholder="Enter Password"
                    isInvalid={error.password !== "" ? true : false}
                  />
                  <InputGroup.Text>
                    {showPassword ? (
                      <VisibilityOff
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Visibility
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {error.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Box>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                name="address"
                onChange={onChangeHandler}
                value={input.address}
                isInvalid={error.address !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                name="pincode"
                type="number"
                onChange={onChangeHandler}
                value={input.pincode}
                placeholder="Enter Pincode"
                isInvalid={error.pincode !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.pincode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city_id"
                onChange={onChangeHandler}
                value={input.city_id}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                name="state_id"
                onChange={onChangeHandler}
                value={input.state_id}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Country</Form.Label>
              <Form.Control
                name="country_id"
                onChange={onChangeHandler}
                value={input.country_id}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-6 col-lg-4">
              <Form.Label>Registration Date</Form.Label>
              <Form.Control
                name="registration_date"
                type="date"
                onChange={onChangeHandler}
                value={`${input.registration_date.toString().split("-")[2]}-${
                  input.registration_date.toString().split("-")[1]
                }-${input.registration_date.toString().split("-")[0]}`}
                isInvalid={error.registration_date !== "" ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {error.registration_date}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3  col-md-6 col-lg-4">
              <Form.Label>Image</Form.Label>
              <Form.Control
                name="logo_image"
                type="file"
                accept="image/*"
                onChange={onChangeHandler}
              />

              {input.logo_image !== "" &&
              input.logo_image !== null &&
              typeof input.logo_image === "string" ? (
                <img
                  style={{
                    maxWidth: "80px",
                    minWidth: "auto",
                    minHeight: "auto",
                    maxHeight: "80px",
                    margin: "10px auto",
                    border: "1px solid #adadad",
                    padding: "5px",
                  }}
                  alt="imageVendors"
                  src={`${REACT_APP_BASE_URL.replace("/api", "")}/${
                    input.logo_image
                  }`}
                />
              ) : (
                input.logo_image !== "" &&
                input.logo_image !== null && (
                  <img
                    style={{
                      maxWidth: "80px",
                      minWidth: "auto",
                      minHeight: "auto",
                      maxHeight: "80px",
                      margin: "10px auto",
                      border: "1px solid #adadad",
                      padding: "5px",
                    }}
                    alt="imageVendors"
                    src={URL.createObjectURL(input.logo_image)}
                  />
                )
              )}
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmitHandler}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditVendor;
